.App {
    text-align: center;
    padding: 0;
  }

  .Main {
    padding: 0;
  }
  
.App-header {
  background-color: rgb(209, 209, 209);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  text-align: center;
}

.homeCard {
  display: flex;
  flex-direction: column;       
  background-color: white;
  border-radius: 16px;
  justify-content: space-evenly;
  align-items: center;
  padding: 3%;
  margin: 5%;
  width: auto;
}

.Button-Group {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.Circular-Button {
  display: flex;
  flex-direction: column;
  background-color: #5EC87C;
  border-radius: 100px;
  color: #5EC87C;
  height: 60px;
  width: 60px;
  justify-content: space-evenly;
  align-items: center; 
  padding: 1%; 
}

.Circular-Button:hover {
  cursor:pointer;
}

.Circular-Button-Dark {
  display: flex;
  flex-direction: column;
  background-color: #f5c71a;
  border-radius: 100px;
  color: #5EC87C;
  height: 60px;
  width: 60px;
  justify-content: space-evenly;
  align-items: center; 
  padding: 1%; 
}

.Circular-Button-Dark :hover {
  cursor: pointer;
}

.App-link {
  color: #61dafb;
}

.Main-Text {
  color: #5EC87C;
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 5%;
  margin-top: 0%;
}

.Sub-Text {
  color: #5EC87C;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10%;
  margin-top: 0%;
}

.Sub-Text-Dark {
  color: #f5c71a;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10%;
  margin-top: 0%;
}

.Icon-Style {
  width: 30px;
  height: 30px;
  color: white;
}

.Ingredients-Input {
  height: 4vh;
  margin: auto;
  width: auto;
}

.Check {
  color: black;
}

.Check:hover {
  cursor: pointer;
}

.Check-Row {
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  
}

.Line {
  color: '#000000';
  background-color: '#000000';
  height: 0.5px;
  width: 200px;     
  border-color : '#000000';
  margin: 5px;
}

.Submit-Button {
  color: #5EC87C;
  margin: 0;
  padding: 10px;
  border: none;
  background: none;
  font-weight: bold;
  font-size: medium;
}

.Submit-Button:hover {
  cursor: pointer;
}

