.App-Header {
    background-color: rgb(209, 209, 209);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    text-align: center;
}
.Card {
  display: flex;
  flex-direction: column;       
  background-color: white;
  border-radius: 16px;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  margin: 10px;
  width: 300px;
}
.Header-Text {
    color: #5EC87C;
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 5%;
    margin-top: 0%;
}
.Option-Text {
    color: #464646;
    font-weight: bold;
    font-size: 23px;
    margin-bottom: 5%;
    margin-top: 0%;
}
.Ingredients-Text{
    color: #464646;
    font-weight: normal;
    font-size: 18px;
    margin-bottom: 5%;
    margin-top: 0%;
}
.Button-Text {
    color: #5EC87C;
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    font-weight: bold;
    font-size: 20px;
}
.Image {
    padding: 150;
    margin: 25;
    width: 250px;
}

.Button {
    color: #5EC87C;
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    font-weight: normal;
    font-size: medium;
}

.Button:hover {
    cursor: pointer;
}

.App-Bar {
    display: flex;
    background-color: white;
    width: 100vw;
    height: 5vh;
    max-height: 50px;
    text-align: center;
    justify-content: center;
    align-items: start;
    padding-top: 2vh;
    margin: 0;
}

.Container {
    font-size: 18px;
    font-weight: 500;
}