.App {
  text-align: center;
}

#Banner {
  display: flex;
  background-color: white;
  width: 100vw;
  height: 5vh;
  max-height: 50px;
  text-align: center;
  justify-content: center;
  align-items: start;
  padding-top: 2vh;
  margin: 0;
}

.App-header {
  background-color: lightgray;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  font-size: calc(10px + 2vmin);
  padding: 10px;
}

.App-Bar {
  display: flex;
  background-color: white;
  width: 100vw;
  height: 5vh;
  max-height: 50px;
  text-align: center;
  justify-content: center;
  align-items: start;
  padding-top: 1vh;
  margin: 0;
}

.Main-Text {
  color: #5EC87C;
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 0%;
  margin-top: 1%;
}

